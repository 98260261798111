var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo}}),_c('PageHeader',{attrs:{"title":_vm.isEditing ? ("" + _vm.titleEdit) : _vm.titleNew}}),_c('v-card',[_c('div',{staticClass:"row"},[_c('v-container',{staticClass:"pt-0"},[_c('v-form',{model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","sm":"6","md":"4"}},[_c('v-textarea',{attrs:{"auto-grow":"","rows":"1","outlined":"","clearable":"","dense":"","rules":_vm.rules.required.concat([
                      _vm.rules.requiredTrim(_vm.nombreFormato),
                      _vm.rules.maxLength(_vm.nombreFormato, 100)
                    ]),"label":"Nombre"},model:{value:(_vm.nombreFormato),callback:function ($$v) {_vm.nombreFormato=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"nombreFormato"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3","sm":"6","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.formatoImpresionTipos,"label":"Formato de impresión","item-value":"id","item-text":"value","rules":_vm.rules.required,"outlined":"","dense":""},on:{"change":function($event){return _vm.setVariables()}},model:{value:(_vm.formatoImpresionTipoSelected),callback:function ($$v) {_vm.formatoImpresionTipoSelected=$$v},expression:"formatoImpresionTipoSelected"}})],1),(_vm.formatoImpresionTipoSelected === 1)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","sm":"6","md":"4"}},[_c('v-select',{attrs:{"items":_vm.tiposComprobantes,"outlined":"","dense":"","return-object":"","clearable":"","label":"Tipos de comprobantes","multiple":"","rules":[
                    _vm.tipoComprobantesSelected.length != 0 || 'Campo requerido'
                  ],"item-text":"value","item-value":"id"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.tipoCompToggle()}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.tipoComprobantesSelected.length > 0
                              ? 'primary'
                              : ''}},[_vm._v(" "+_vm._s(_vm.multiselectIconTiposComp)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Todos ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
                              var item = ref.item;
                              var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.value))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.tipoComprobantesSelected.length - 1)+" otros) ")]):_vm._e()]}}],null,false,1215374609),model:{value:(_vm.tipoComprobantesSelected),callback:function ($$v) {_vm.tipoComprobantesSelected=$$v},expression:"tipoComprobantesSelected"}})],1):_vm._e(),(_vm.formatoImpresionTipoSelected === 2)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","sm":"6","md":"4"}},[_c('v-select',{attrs:{"items":_vm.tiposValores,"outlined":"","dense":"","clearable":"","return-object":"","label":"Tipos de valores","multiple":"","rules":[
                    _vm.tiposValoresSelected.length != 0 || 'Campo requerido'
                  ],"item-text":"value","item-value":"id"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.tipoValorToggle()}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.tiposValoresSelected.length > 0 ? 'primary' : ''}},[_vm._v(" "+_vm._s(_vm.multiselectIconTiposValores)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Todos ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
                  var item = ref.item;
                  var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.value))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.tiposValoresSelected.length - 1)+" otros) ")]):_vm._e()]}}],null,false,3795588263),model:{value:(_vm.tiposValoresSelected),callback:function ($$v) {_vm.tiposValoresSelected=$$v},expression:"tiposValoresSelected"}})],1):_vm._e(),(_vm.formatoImpresionTipoSelected != null)?_c('v-col',{staticClass:"pt-2",attrs:{"cols":"1","sm":"1","md":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text"},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.infoIcon)+" ")])]}}],null,false,1031365674)},[(_vm.formatoImpresionTipoSelected === 1)?_c('span',[_vm._v(" Se excluyen los tipos de comprobantes configurados en otro formato. ")]):_c('span',[_vm._v("Se excluyen los tipos de valores configurados en otro formato.")])])],1):_vm._e()],1)],1),_c('div',{staticClass:"col-12 py-0"},[_c('TextEditor',{ref:"text",attrs:{"value":_vm.content,"variables":_vm.variables.map(function (item) { return ({
                  nombre: item.description,
                  varId: item.id,
                  variable: item.value
                }); })},on:{"update:value":function($event){_vm.content=$event}}})],1),_c('v-container',[_c('v-row',{staticClass:"pr-4 pl-4 pt-4",attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.isFormValid,"loading":_vm.loadingSaveBtn},on:{"click":_vm.saveContent}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }