<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" />
    <PageHeader :title="isEditing ? `${titleEdit}` : titleNew" />
    <v-card>
      <div class="row">
        <v-container class="pt-0">
          <v-form v-model="isFormValid">
            <v-container>
              <v-row>
                <v-col cols="4" sm="6" md="4" class="py-0">
                  <v-textarea
                    auto-grow
                    rows="1"
                    outlined
                    clearable
                    dense
                    :rules="
                      rules.required.concat([
                        rules.requiredTrim(nombreFormato),
                        rules.maxLength(nombreFormato, 100)
                      ])
                    "
                    label="Nombre"
                    v-model.trim="nombreFormato"
                  ></v-textarea>
                </v-col>
                <v-col cols="3" sm="6" md="3" class="py-0">
                  <v-autocomplete
                    v-model="formatoImpresionTipoSelected"
                    :items="formatoImpresionTipos"
                    label="Formato de impresión"
                    item-value="id"
                    item-text="value"
                    :rules="rules.required"
                    @change="setVariables()"
                    outlined
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="4"
                  sm="6"
                  md="4"
                  class="py-0"
                  v-if="formatoImpresionTipoSelected === 1"
                >
                  <v-select
                    v-model="tipoComprobantesSelected"
                    :items="tiposComprobantes"
                    outlined
                    dense
                    return-object
                    clearable
                    label="Tipos de comprobantes"
                    multiple
                    :rules="[
                      tipoComprobantesSelected.length != 0 || 'Campo requerido'
                    ]"
                    item-text="value"
                    item-value="id"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="tipoCompToggle()">
                        <v-list-item-action>
                          <v-icon
                            :color="
                              tipoComprobantesSelected.length > 0
                                ? 'primary'
                                : ''
                            "
                          >
                            {{ multiselectIconTiposComp }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Todos </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item.value }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption">
                        (+{{ tipoComprobantesSelected.length - 1 }} otros)
                      </span>
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="4"
                  sm="6"
                  md="4"
                  class="py-0"
                  v-if="formatoImpresionTipoSelected === 2"
                >
                  <v-select
                    v-model="tiposValoresSelected"
                    :items="tiposValores"
                    outlined
                    dense
                    clearable
                    return-object
                    label="Tipos de valores"
                    multiple
                    :rules="[
                      tiposValoresSelected.length != 0 || 'Campo requerido'
                    ]"
                    item-text="value"
                    item-value="id"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="tipoValorToggle()">
                        <v-list-item-action>
                          <v-icon
                            :color="
                              tiposValoresSelected.length > 0 ? 'primary' : ''
                            "
                          >
                            {{ multiselectIconTiposValores }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Todos </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item.value }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption">
                        (+{{ tiposValoresSelected.length - 1 }} otros)
                      </span>
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="1"
                  sm="1"
                  md="1"
                  class="pt-2"
                  v-if="formatoImpresionTipoSelected != null"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="primary--text" v-bind="attrs" v-on="on">
                        {{ infoIcon }}
                      </v-icon>
                    </template>
                    <span v-if="formatoImpresionTipoSelected === 1">
                      Se excluyen los tipos de comprobantes configurados en otro
                      formato.
                    </span>
                    <span v-else
                      >Se excluyen los tipos de valores configurados en otro
                      formato.</span
                    >
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
            <div class="col-12 py-0">
              <TextEditor
                ref="text"
                :value.sync="content"
                :variables="
                  variables.map(item => ({
                    nombre: item.description,
                    varId: item.id,
                    variable: item.value
                  }))
                "
              />
            </div>
            <v-container>
              <!-- <v-row>
                <v-col cols="3" class="py-0">
                  <v-autocomplete
                    v-model="variableSelected"
                    label="Variables"
                    :items="variables"
                    item-text="value"
                    item-value="id"
                    dense
                    outlined
                  >
                    <template slot="item" slot-scope="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>{{
                          item.description
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >Variable: {{ item.value }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-btn color="primary" @click="addVariable">
                  Insertar
                </v-btn>
              </v-row> -->
              <v-row class="pr-4 pl-4 pt-4" justify="end">
                <v-btn
                  color="primary"
                  :disabled="!isFormValid"
                  @click="saveContent"
                  :loading="loadingSaveBtn"
                >
                  Guardar
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-container>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";
// import TextEditor from "@/components/textEditor/TextEditor.vue";
import TextEditor from "@/components/shared/TextEditor.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  name: "EditFormatoImpresionOrdenesPago",
  components: {
    PageHeader,
    TextEditor,
    GoBackBtn
  },
  data() {
    return {
      routeToGo: "FormatosImpresionOrdenesPago",
      titleNew: enums.titles.NUEVO_FORMATO_IMPRESION,
      titleEdit: enums.titles.EDITAR_FORMATO_IMPRESION,
      infoIcon: enums.icons.SNB_INFO,
      content: "",
      contentChanged: false,
      rules: rules,
      isFormValid: false,
      variableSelected: null,
      formatoImpresionId: null,
      modulo: "P",
      tiposValores: [],
      tiposValoresSelected: [],
      tiposComprobantes: [],
      tipoComprobantesSelected: [],
      variables: [],
      variableSelected: null,
      formatoImpresionTipos: [],
      formatoImpresionTipoSelected: null,
      nombreFormato: null,
      loadingSaveBtn: false
    };
  },
  created() {
    this.setView();
  },
  computed: {
    isEditing() {
      return this.$route.params.formato;
    },
    multiselectIconTiposComp() {
      if (this.selectAllTiposComp) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllTiposComp() {
      return (
        this.tipoComprobantesSelected &&
        this.tipoComprobantesSelected.length === this.tiposComprobantes.length
      );
    },
    multiselectIconTiposValores() {
      if (this.selectAllTiposValores) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllTiposValores() {
      return (
        this.tiposValoresSelected &&
        this.tiposValoresSelected.length === this.tiposValores.length
      );
    }
  },
  methods: {
    ...mapActions({
      fetchTipoValoresRetenciones: "proveedores/fetchTipoValoresRetenciones",
      getTiposComprobantesOPByFormatoImpresion:
        "proveedores/getTiposComprobantesOPByFormatoImpresion",
      fetchFormatoImpresionVariables:
        "proveedores/fetchFormatoImpresionVariables",
      fetchFormatoImpresionTipo: "proveedores/fetchFormatoImpresionTipo",
      saveFormatoImpresionOP: "proveedores/saveFormatoImpresionOP",
      setAlert: "user/setAlert"
    }),
    async setView() {
      const formatoImpresionId =
        this.$route.params.formato?.formatoImpresionId ?? 0;
      const tipoValores = await this.fetchTipoValoresRetenciones(
        formatoImpresionId
      );
      this.tiposValores = tipoValores;
      const tipoComprobantes = await this.getTiposComprobantesOPByFormatoImpresion(
        formatoImpresionId
      );
      this.tiposComprobantes = tipoComprobantes;
      const formatoImpresionTipos = await this.fetchFormatoImpresionTipo(
        this.modulo
      );
      this.formatoImpresionTipos = formatoImpresionTipos;

      // si entra a editar
      if (this.$route.params.formato) {
        this.setFormato();
      }
    },
    async setVariables() {
      const variables = await this.fetchFormatoImpresionVariables(
        this.formatoImpresionTipoSelected
      );
      this.variables = variables;
    },
    setFormato() {
      const template = this.$route.params.formato;
      this.formatoImpresionId = template.formatoImpresionId;
      this.content = template.htmlContent;
      this.nombreFormato = template.nombre;

      if (template.tipoValores.length > 0) {
        template.tipoValores.forEach(x => {
          const findTipoValor = JSON.parse(
            JSON.stringify(this.tiposValores)
          ).find(tv => tv.id === x);
          if (findTipoValor != null)
            this.tiposValoresSelected.push(findTipoValor);
        });
      }

      if (template.tipoComprobantes.length > 0) {
        template.tipoComprobantes.forEach(x => {
          const findTipoComp = JSON.parse(
            JSON.stringify(this.tiposComprobantes)
          ).find(tc => tc.id === x);
          if (findTipoComp != null)
            this.tipoComprobantesSelected.push(findTipoComp);
        });
      }

      this.formatoImpresionTipoSelected = template.tipoImpresionId;
      this.setVariables();
    },
    async saveContent() {
      this.loadingSaveBtn = true;
      let res = null;
      if (this.content.trim() === null || this.content.trim() === "") {
        this.setAlert({
          type: "warning",
          message: "El cuerpo del formato de impresión está vacío"
        });
        this.loadingSaveBtn = false;
      } else {
        const data = {
          formatoImpresionId: this.formatoImpresionId,
          nombre: this.nombreFormato,
          tipoImpresionId: this.formatoImpresionTipoSelected,
          tipoComprobantes:
            this.tipoComprobantesSelected.length > 0
              ? this.tipoComprobantesSelected.map(x => x.id)
              : [],
          tipoValores:
            this.tiposValoresSelected.length > 0
              ? this.tiposValoresSelected.map(x => x.id)
              : [],
          htmlContent: this.content
        };
        res = await this.saveFormatoImpresionOP(data);
      }
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.$router.push({ name: this.routeToGo });
      } else {
        this.loadingSaveBtn = false;
      }
    },
    async addVariable() {
      this.variableSelected = this.variables.find(
        x => x.id === this.variableSelected
      ).value;

      const Editor = this.$refs.text.$refs.editor.quill;
      let cursorLocation = Editor.selection.savedRange.index;
      Editor.editor.insertText(cursorLocation, this.variableSelected);
    },
    tipoCompToggle() {
      this.$nextTick(() => {
        if (this.selectAllTiposComp) {
          this.tipoComprobantesSelected = [];
        } else {
          this.tipoComprobantesSelected = this.tiposComprobantes;
        }
      });
    },
    tipoValorToggle() {
      this.$nextTick(() => {
        if (this.selectAllTiposValores) {
          this.tiposValoresSelected = [];
        } else {
          this.tiposValoresSelected = this.tiposValores;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped></style>
